import React, { useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import { Icon } from '@iconify/react'
import cowIcon from '@iconify-icons/whh/cow'
import axios from 'axios'

const LocationPin = ({ text }) => (
  <div className="pin">
    <Icon icon={cowIcon} className="pin-icon" />
    <p className="pin-text">{text}</p>
  </div>
);

export const Map = ({ location, zoomLevel }) => {

  const [isLoading, setLoading] = useState(true);
  const [Apikey, setApikey] = useState(true);

  useEffect(() => {
    axios.get('https://europe-west2-thunderingherd-20fe4.cloudfunctions.net/app/milk')
      .then(response => {
        setApikey(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error(error)
        setApikey(null)
        setLoading(false);

        return null
      });
  }, []);

  if (isLoading) {
    return <div className="training_map">Loading...</div>;
  }

  return(
    <div id='training_map'>
      <div className="google-map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: Apikey }}
          defaultCenter={location}
          defaultZoom={zoomLevel}
        >
        <LocationPin
          lat={location.lat}
          lng={location.lng}
          text={location.address}
        />
        </GoogleMapReact>
      </div>
    </div>
  )
}